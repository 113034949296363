<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'home' }" class="root">
                Home
            </router-link>
            /
            <router-link :to="{name: 'search-catalog-home' }" class="root">
             OPAC Search
            </router-link>
            <span class="child">/ Book Item </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">Book Item</v-card-title>
            <v-card-text>
                <div class="bookdata">
                    <div class="bookInfo">
                        <v-col sm="6" class="value" lg="1">ISBN</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.isbn}}</v-col>
                        <v-col sm="6" class="value" lg="1">Title</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.title}}</v-col>
                        <v-col sm="6" class="value" lg="1">Copies</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.copies}}</v-col>
                    </div>
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Edition</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.edition}}</v-col>
                        <v-col sm="6" class="value" lg="1">Volume</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.volume}}</v-col>
                        <v-col sm="6" class="value" lg="1">Book Format</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.bookFormat}}</v-col>
                    </div>
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Book Type</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.bookType}}</v-col>
                        <v-col sm="6" class="value" lg="1">Category</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.category}}</v-col>
                        <v-col sm="6" class="value" lg="1">Publisher</v-col>
                        <v-col sm="6" lg="3" class="data">: {{bookData.publisher}}</v-col>
                    </div>
                    <div class="d-flex">
                        <v-col sm="6" class="value" lg="1">Author(s)</v-col>
                        <v-col sm="6" lg="11" class="data">: <span class="pr-3" v-for="(auth,index) in bookData.authors" :key="index">{{bookData.authors[index]}}</span></v-col>
                    </div>
                </div>
                <v-data-table :headers="headers" :items="bookItemlist" class="elevation-1 nowrap" :search="search">
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" single-line hide-details> </v-text-field>
                            <span style="width: 20px;"></span>
                            <!--<download-excel class="mar" :data="bookItemlist" :fields="export_table_headers" worksheet="bookItemlist" name="BookItem.xls">
                                <v-btn color="success" dark class="mb-2"> Export<v-icon dark>mdi-file-excel</v-icon> </v-btn>
                            </download-excel>-->
                        </v-toolbar>
                    </template>
                    <template v-slot:item.bookItem="{ item }">
                        <router-link :to="{ name: 'book-item', params: {bookId: item.id} }" style="text-decoration: none;">
                            <v-btn small color="info">Book Item</v-btn>
                        </router-link>
                    </template>
                        <template v-slot:item.borrowed_date="{ item }">
                       <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
                       <p class="m-0" v-else>{{item.borrowed_date}}</p>
                    </template>

                    <template v-slot:item.due_date="{ item }">
                       <p class="m-0" v-if="item.book_status !== 'Issued'">-</p>
                       <p class="m-0" v-else>{{item.due_date}}</p>

                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    //import moment from "moment";

    import "@/assets/css/color.css"; 
    export default {
        data: () => ({
            export_table_headers: {
                "Sr.no.": "srno",
                "Accession No": "access_no",
                "Book Status": "book_status",
                "Rack No": "rackNo",
                "Block No": "block_no",
            },
           showPicker5: false,
            bookData: [],
            bookItemlist: [],
            racklist: [],
            bookStatus: [],
            valid: false,
            overlay: false,
            errorMsg: {
                roleLinkId: false,
            },
            snackbar_msg: "",
            color: "",
            snackbar: false,
            search: "",
            showPicker: false,
            showPicker1: false,
            selectedDate: null,
            menu1: false,
            menu2: false,
            org: "",
            inSave: true,
            isRackAvailable:true,
            dialog: false,
            tenantid:"",
            organizationid:"",
            headers: [
                {
                    text: "Sr no",
                    align: "left",
                    sortable: true,
                    value: "srno",
                },
                {
                    text: "Accession Number",
                    align: "left",
                    sortable: true,
                    value: "access_no",
                },
                {
                    text: "Barcode",
                    align: "left",
                    sortable: true,
                    value: "barcode",
                },
                {
                    text: "Book Status",
                    align: "left",
                    sortable: true,
                    value: "book_status",
                },

                {
                    text: "Member",
                    align: "left",
                    sortable: true,
                    value: "member",
                },
                {
                    text: "Issue Date",
                    align: "left",
                    sortable: true,
                    value: "borrowed_date",
                },
                {
                    text: "Due Date",
                    align: "left",
                    sortable: true,
                    value: "due_date",
                },
                {
                    text: "Rack No",
                    align: "left",
                    sortable: true,
                    value: "rackNo",
                },
                {
                    text: "Block No",
                    align: "left",
                    sortable: true,
                    value: "block_no",
                },
                {
                    text: "Withdrawal No",
                    align: "left",
                    sortable: true,
                    value: "w_no",
                },                {
                    text: "Withdrawal Date",
                    align: "left",
                    sortable: true,
                    value: "w_date",
                },                {
                    text: "Publication Place",
                    align: "left",
                    sortable: true,
                    value: "p_place",
                },
            ],
            bookCategoryList: [],
            roleLink_list: [],
            blockList: [],
            role_list: [],
            editedIndex: -1,
            editedItem: {
                      w_date:"",
               w_no:'',
               p_place:'',
                isactive: false,
                id: "",
                access_no: null,
                barcode: null,
                book_status: null,
                borrowed_date: null,
                due_date: null,
                rackNo: null,
                block_no: null,
                book_statusId: null,
                bookStatus: null,
                member: null,
                memnerId: null,
                rackId: null,
            },
            defaultItem: {
                isactive: false,
                id: "",
                access_no: null,
                barcode: null,
                book_status: null,
                borrowed_date: null,
                due_date: null,
                rackNo: null,
                block_no: null,
                book_statusId: null,
                bookStatus: null,
                member: null,
                memnerId: null,
                rackId: null,
            },
        }),

        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "New Book Item" : "Edit Book Item";
            },
        },
        mounted() {
            this.onLoad();
        },
        methods: {
            onLoad() {
                let data = {
                    bookId: this.$route.params.bookId,
                    tenantid: this.$route.params.tenantid,
                    organizationid : this.$route.params.organizationid,
                };
                this.overlay = true;
                axios
                    .post("/SearchCatalog/getBookItemdataHome", data)
                    .then((res) => {
                        if (res.data.msg == "200") {
                            this.overlay = false;
                            this.bookData = res.data.bookData;
                            this.bookItemlist = res.data.bookItemlist;
                            this.racklist = res.data.racklist;
                            this.bookStatus = res.data.bookStatus;

                            this.bookList = res.data.book_list;
                            this.bookTypeList = res.data.bookTypeList;
                            this.bookFormatList = res.data.bookFormatList;
                            this.bookCatList = res.data.bookCatList;
                            this.languageList = res.data.languageList;
                            this.publisherList = res.data.publisherList;
                            this.libraryList = res.data.lib_list;
                            this.authorList = res.data.authorList;
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong..."); 
                        window.console.log(error);
                    })
                    .finally(() => {
                        
                    });
            },
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            close() {
                this.dialog = false;
            },
        },
    };
</script>
<style scoped>
    .data {
        padding-left: 20px;
    }
    .bookdata {
        border: 2px solid gray;
        background: #d3d3d330;
        margin: 1rem 0rem 1rem 0rem;
        border-radius: 5px;
    }
    .value {
        white-space: nowrap;
        font-weight: bold;
        color: black;
    }
    .v-text-field {
        padding-top: 0px;
        margin-top: 0px;
    }
    .v-icon-size {
        font-size: 20px;
    }
    .edit-avatar {
        transition: 0.3s;
        border: 1px solid #b0bec5;
    }
    .edit-avatar:hover {
        background-color: #b0bec5;
    }
    .edit-v-icon {
        padding-left: 6px;
        font-size: 15px;
    }
    .edit-v-icon:hover {
        color: white;
    }
    .fields {
        padding: 0px !important;
    }
    .isdonated {
        margin-top: 15px;
        padding-left: 15px !important;
    }
    .bookInfo {
        display: flex;
    }
    @media only screen and (max-width: 600px) {
        .bookInfo {
            /* display: block; */
        }
    }
</style>
